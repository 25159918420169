import { Middleware } from '@nuxt/types'

const authenticated: Middleware = ({ app, store, route, redirect}) => {
    if (app.$fire.auth.currentUser !== null) {

    } else {
        return redirect('/login');
    }
    /*if (app.$fire.auth && route.name === 'login') {
        console.log('test1')
        return redirect('/');
    }*/
}

export default authenticated