//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Header',
  props: ['storeName','storeDomain'],
  data () {
    return {

    }
  },
  methods: {
    async logout() {
      try {
        await this.$fire.auth.signOut()
        await this.$router.push('/login');
      } catch (e) {
        alert(e)
      }
    },
  }
}
