











import axios from "axios";
import Cookies from 'js-cookie'
import { mapState, mapGetters } from 'vuex'
import Header from "~/components/Header.vue";
import Nav from "~/components/Nav.vue";

export default {
  data() {
    return {
      loggedIn: false,
      loaded: false,
      storePlan: "",
      storeName: "",
      storeDomain: "",
    }
  },
  components: {
    Header,
    Nav
  },
  computed: {
    ...mapState({
      authUser: (state: any) => state.authUser
    }),
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    }),
  },
  async mounted() {
    await this.setupFirebase();
    //await this.setupFirebaseDev();
  },
  methods: {
    async setupFirebaseDev() {
      await axios.post(
          "http://localhost:5000/api/user",
          {
            uid: ""
          }).then((response) => {
        this.storeDomain = response.data.data.storeDomain
        this.storeName = response.data.data.storeName
        this.loggedIn = true
      });
    },
    setupFirebase() {
      this.$fire.auth.onAuthStateChanged(async user => {
        if (user) {
          this.$fire.auth.currentUser.getIdToken(true)
            .then(token => Cookies.set('access_token', token))
          try {
            await axios.post(
                "/api/user",
                {
                  uid: this.$fire.auth.currentUser.uid
                }).then((response) => {
              this.storeDomain = response.data.data.storeDomain
              this.storeName = response.data.data.storeName
              this.storePlan = response.data.data.plan
              this.loggedIn = true
            });
          } catch (e) {
            console.log(e);
            await this.$router.push('/login');
          }
        } else {
          Cookies.remove('access_token');
          this.loggedIn = false;
          await this.$router.push('/login');
        }
      })
    },
  }
}
