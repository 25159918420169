import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e3400bcc = () => interopDefault(import('../pages/advanced.vue' /* webpackChunkName: "pages/advanced" */))
const _276e9d54 = () => interopDefault(import('../pages/blackfriday.vue' /* webpackChunkName: "pages/blackfriday" */))
const _edffead4 = () => interopDefault(import('../pages/collection.vue' /* webpackChunkName: "pages/collection" */))
const _3dcce121 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _08ff659b = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _7539800b = () => interopDefault(import('../pages/bundle/new.vue' /* webpackChunkName: "pages/bundle/new" */))
const _699cdff3 = () => interopDefault(import('../pages/byob/new.vue' /* webpackChunkName: "pages/byob/new" */))
const _6eb50b26 = () => interopDefault(import('../pages/setup/register.vue' /* webpackChunkName: "pages/setup/register" */))
const _2d7470f4 = () => interopDefault(import('../pages/metafields/_.vue' /* webpackChunkName: "pages/metafields/_" */))
const _1f6b71f2 = () => interopDefault(import('../pages/byob/_.vue' /* webpackChunkName: "pages/byob/_" */))
const _5d3d8fec = () => interopDefault(import('../pages/bundle/_.vue' /* webpackChunkName: "pages/bundle/_" */))
const _74253e0a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/advanced",
    component: _e3400bcc,
    name: "advanced"
  }, {
    path: "/blackfriday",
    component: _276e9d54,
    name: "blackfriday"
  }, {
    path: "/collection",
    component: _edffead4,
    name: "collection"
  }, {
    path: "/login",
    component: _3dcce121,
    name: "login"
  }, {
    path: "/settings",
    component: _08ff659b,
    name: "settings"
  }, {
    path: "/bundle/new",
    component: _7539800b,
    name: "bundle-new"
  }, {
    path: "/byob/new",
    component: _699cdff3,
    name: "byob-new"
  }, {
    path: "/setup/register",
    component: _6eb50b26,
    name: "setup-register"
  }, {
    path: "/metafields/*",
    component: _2d7470f4,
    name: "metafields-all"
  }, {
    path: "/byob/*",
    component: _1f6b71f2,
    name: "byob-all"
  }, {
    path: "/bundle/*",
    component: _5d3d8fec,
    name: "bundle-all"
  }, {
    path: "/",
    component: _74253e0a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
