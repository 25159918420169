import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  ColorChooser: () => import('../../components/ColorChooser.vue' /* webpackChunkName: "components/color-chooser" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  ImageUpload: () => import('../../components/ImageUpload.vue' /* webpackChunkName: "components/image-upload" */).then(c => wrapFunctional(c.default || c)),
  Login: () => import('../../components/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c)),
  Nav: () => import('../../components/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c)),
  ProductCard: () => import('../../components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c)),
  ProductList: () => import('../../components/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c)),
  ProductListModal: () => import('../../components/ProductListModal.vue' /* webpackChunkName: "components/product-list-modal" */).then(c => wrapFunctional(c.default || c)),
  Skeleton: () => import('../../components/Skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c)),
  RewardsDiscountSettings: () => import('../../components/rewards/DiscountSettings.vue' /* webpackChunkName: "components/rewards-discount-settings" */).then(c => wrapFunctional(c.default || c)),
  RewardsFreeGift: () => import('../../components/rewards/FreeGift.vue' /* webpackChunkName: "components/rewards-free-gift" */).then(c => wrapFunctional(c.default || c)),
  RewardsFreeShipping: () => import('../../components/rewards/FreeShipping.vue' /* webpackChunkName: "components/rewards-free-shipping" */).then(c => wrapFunctional(c.default || c)),
  RewardsRewardBlock: () => import('../../components/rewards/RewardBlock.vue' /* webpackChunkName: "components/rewards-reward-block" */).then(c => wrapFunctional(c.default || c)),
  CollectionBuildYourOwnBundle: () => import('../../components/collection/BuildYourOwnBundle.vue' /* webpackChunkName: "components/collection-build-your-own-bundle" */).then(c => wrapFunctional(c.default || c)),
  CollectionBundles: () => import('../../components/collection/Bundles.vue' /* webpackChunkName: "components/collection-bundles" */).then(c => wrapFunctional(c.default || c)),
  CollectionEditProduct: () => import('../../components/collection/EditProduct.vue' /* webpackChunkName: "components/collection-edit-product" */).then(c => wrapFunctional(c.default || c)),
  CollectionProducts: () => import('../../components/collection/Products.vue' /* webpackChunkName: "components/collection-products" */).then(c => wrapFunctional(c.default || c)),
  CollectionSubscribeSave: () => import('../../components/collection/SubscribeSave.vue' /* webpackChunkName: "components/collection-subscribe-save" */).then(c => wrapFunctional(c.default || c)),
  SettingsAppearance: () => import('../../components/settings/Appearance.vue' /* webpackChunkName: "components/settings-appearance" */).then(c => wrapFunctional(c.default || c)),
  SettingsCart: () => import('../../components/settings/Cart.vue' /* webpackChunkName: "components/settings-cart" */).then(c => wrapFunctional(c.default || c)),
  SettingsGeneral: () => import('../../components/settings/General.vue' /* webpackChunkName: "components/settings-general" */).then(c => wrapFunctional(c.default || c)),
  SettingsPhrases: () => import('../../components/settings/Phrases.vue' /* webpackChunkName: "components/settings-phrases" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
