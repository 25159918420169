import initialState from './state'
export default {
    RESET_STORE: (state) => {
        Object.assign(state, initialState())
    },
    SET_AUTH_USER: async (state, { authUser }) => {
        state.authUser = {
            uid: authUser.uid,
            email: authUser.email
        }
    }
}